@use 'sass:color';
@use 'src/styles/variables' as variables;
@use 'grapesjs/dist/css/grapes.min.css';
@use 'src/app/page-builder/page-builder/styles/editor.scss';
@use 'src/styles/responsive.scss';


$opened-modal-body-overflow: visble;

html {
  font-size: 10px;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
}

html body {
  color: variables.$primary-color;
  background-color: variables.$body-bg-color;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 1.45rem;
  & .header,
  & .sidebar,
  & h1, & h2, & h3, & h4, & h5, & h6,
  & button, & .button
  & .settings-nav-list li,
  & .account-nav-list li,
  & .general-nav-list li,
  & input[type=submit],
  & thead th {
    font-family: 'Muli', system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    letter-spacing: .4px;
  }
  & .settings-nav-list li {
    font-family: 'Muli', system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif;
    color: color.adjust(variables.$primary-color, $lightness: 5%)
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

html body h1 {
  font-size: 2.8rem;
}

html body h2 {
  font-size: 2.3rem;
}

html body h3 {
  font-size: 1.8rem;
}

a, button {
  transition: all 250ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

[btnDisabled] {
  & > a,
  & > button,
  & > i {
    pointer-events: none;
    cursor: not-allowed;
  }
}

a {
  color: variables.$secondary-color;
  &:hover {
    color: variables.$secondary-color-dark;
  }
  &:focus {
    outline: none;
  }
}

a {
  color: inherit;
}

body button,
input[type="submit"],
body button.submit,
a.button {
  border:0;
  padding: 1.2rem 2rem;
  border-radius: .4rem;
  font-weight: 700;
  font-size:1.35rem;
  background-color: variables.$secondary-color;
  color: variables.$primary-bg-color;
  cursor: pointer;
  transition: all .25s ease-out;
  letter-spacing: 0.1rem;
  &:hover {
    background-color: variables.$secondary-color-light;
  }
  &:focus {
    outline:none;
  }
  &.alt-button {
    background-color: transparent;
    border: 2px solid variables.$secondary-color;
    color: variables.$secondary-color;
    padding-top: calc(1rem - 2px);
    padding-bottom: calc(1rem - 2px);
    text-decoration: none !important;
    &:hover {
      background-color: variables.$secondary-color !important;
      color: variables.$primary-bg-color !important;
    }
  }
  &.delete-button {
    background-color: variables.$error-color;
    &:hover {
      background-color: color.adjust(variables.$error-color, $lightness: -10%);
    }
  }
}

.main-card {
  padding: 3.2rem 3.8rem;
  background-color: variables.$settings-background-color; 
  box-shadow: variables.$default-box-shadow;
  border-radius: .4rem;
  position: relative;
  height: auto;
}

// hide scrollbar when paddle checkout is open
body:has(iframe.paddle-frame) {
  overflow: hidden !important;
}

body input,
body textarea,
body select {
  &.border {
    border-width: 1px;
    border-color: variables.$default-border-color;
  }
  &:focus {
    border-color: variables.$secondary-color;
    outline:none;
  }
}

body .shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 6%);
}

body .bg-gray-400 {
  background-color: #cbd5e0;
  background-color: rgba(203,213,224,1);
}

body input[disabled="disabled"],
body input[disabled="true"] {
  background-color: rgba(0,0,0,0.03);
  border-color: rgba(0,0,0,0.05);
  color: rgba(0,0,0,.8);
}

body .advanced-input-container input {
  background-color: initial;
  color: initial;
  border-color: initial;
}

body form .desc {
  margin-left: 0;
}

body .integration-loader {
  display:none;
}

body i-feather {
  stroke-width: 1px !important;
  position: relative;
  white-space: nowrap;
  height: auto !important;
}

body {
  & button, & .button {
    & i-feather {
      stroke-width: 2px !important;
      top: .3rem;
      width: 1.6rem !important;
    }
  }
  & table i-feather {
    top: .2rem;
    width: 1.6rem !important;
  }
  & td.table-title, .skeleton td:first-child {
    min-width: 300px;
    font-weight: 700;
    & a {
      text-align: left !important;
    }
  }
  & .table-actions {
    font-size: 1.25rem;
    margin-left: 1.4rem;
    margin-right: 1.4rem;
    & select {
      margin-bottom: 0;
      padding: .5rem .9rem;
      width: auto;
      min-width: 15rem;
      background-position: calc(100% - 12px) 1.1rem, calc(100% - 7px) 1.1rem, calc(100% - 1.7em) 0.3em;
    }
    & .results-per-page select {
      max-width: 6.2rem;
      min-width: 6.2rem;
    } 
    & .table-controls {
      gap: 4rem;
    }
  }
  table.content-table {
    & td {
      border-bottom: .8px solid #e5e7eb;
      padding: 1.8rem 1.4rem;
      text-align: center;
      &:has(a) {
        padding: 0;
      }
      & a {
        padding: 1.8rem 1.4rem;
        display: block;
        text-align: center;
      }
      &.data-date {
        text-align: center;
      }
    }
    & th {
      padding: 1rem 1.4rem;
      border-bottom: .8px solid #e5e7eb;
      text-align: center;
      &:first-child {
        text-align: left;
      }
      & i-feather {
        stroke-width: 2px !important;
        top: 3px !important;
      }
      &.date {
        cursor: pointer;
        min-width: 16rem;
      }
    }
    & tbody tr:hover {
      background: #f0f0f0;
    }
    & tbody .skeleton-loader {
      margin: 0;
    }
    & tbody .skeleton td {
      padding: 1.1rem 1.4rem 0.5rem 1.4rem !important
    }
    & tbody tr.skeleton:hover {
      background: transparent;
    }
    & .draft-indicator, .scheduled-indicator, .home-indicator {
      color: variables.$primary-color;
      opacity: .8;
    }
  } 
  .comment-table table.content-table td {
    text-align: left !important;
  }
  .results-per-page, .category-filter, .status-filter {
    gap: 1.2rem;
  }
}

body [data-tooltip] {
  &:before {
    content: attr(data-tooltip);
    position: absolute;
    background: variables.$sidebar-bg-color;
    color: rgba(255,255,255,0.75);
    font-size: 1.1rem;
    padding: 1.1rem 1.3rem 1.1rem 1.3rem;
    border-radius: .4rem;
    line-height: 1.5;
    text-align: left;
    top: -5rem;
    left: 0;
    width: auto;
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
    transition-delay: 0s;
    pointer-events: none;
    opacity: 0;
    z-index: 999999;
  }
  &:hover {
    &:before {
      transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;
      transition-delay: 120ms;
      transform: none;
      opacity: 1;
    }
  }
}

.prosemirror-dropcursor-block {
  background-color: variables.$secondary-color !important;
  height: 2px !important;
}

body .monaco-editor {
  border:1px solid variables.$default-border-color;
  border-radius: .375rem;
  overflow:hidden;
  & .minimap {
    display:none;
  }
  & .view-overlays .current-line { border-color: transparent; }
  & .margin-view-overlays .current-line-margin { border-color: transparent; }
  & .lines-content .cigr { box-shadow: none; }
  & .decorationsOverviewRuler { display: none; }
}

.dashboard-content input:checked ~ .toggle__dot,
.dashboard-content .main-toggle.active .toggle__dot {
  background-color: variables.$secondary-color;
}

body #toast-container {
  position: fixed;
  right: 2%;
  bottom: 2%;
  z-index: 999999999;
  & .ngx-toastr {
    display: block;
    position: relative;
    padding: 1.2rem 1.8rem;
    border-radius: .375rem;
    font-size: 1.3rem;
    letter-spacing: .02rem;
    margin-bottom: 1.2rem;
    &.toast-success {
      background: variables.$success-color;
      color: #fff;
    }
    &.toast-error {
      background: variables.$error-color;
      color: #fff;
    }
  }
}

body .icon-picker {
  padding: 1rem;
  & div.icon-grid div,
  div.icon-grid button {
    width: auto;
    padding: 0 !important;
    margin: 0 !important;
  }
  & button, button:hover {
    background: transparent;
    color: #2a2a2a;
    width: auto !important;
  }
  & span {
    font-size: 2rem !important;
    margin-right: 1rem !important ;
    margin-bottom: 2rem !important;
    width: 2.5rem;
  }
  & .far, .fas, .fal, .fad, .fab {
    display:none;
  }
}

/* Email / TikTok / Mastodon hack*/
i.selected-input-icon.fab.fa-accessible-icon:before,
icon-picker .fa-accessible-icon:before {
  content: "\e07b" !important;
}
icon-picker span.fa-gg.fab:before,
i.selected-input-icon.fab.fa-gg:before {
  content: "\f0e0" !important;
  font-family: 'Font Awesome 6 Free' !important;
}
i.selected-input-icon.fab.fa-gg-circle:before,
icon-picker .fa-gg-circle:before {
  content: "\f4f6" !important;
}
icon-picker span.fa-accusoft.fab:before,
i.selected-input-icon.fab.fa-accusoft:before {
  content: "\e61b" !important;
}
icon-picker span.fa-adn.fab:before,
i.selected-input-icon.fab.fa-adn:before {
  content: "\e618" !important;
}

icon-picker span.fa-alipay.fab:before,
i.selected-input-icon.fab.fa-alipay:before {
  content: "\e671" !important;
}

input.default[data-icon="fab fa-accessible-icon"],
input.default[data-icon="fab fa-gg"],
input.default[data-icon="fab fa-gg-circle"],
input.default[data-icon="fab fa-adn"],
input.default[data-icon="fab fa-alipay"],
input.default[data-icon="fab fa-accusoft"] {
  color: transparent;
}

.ngx-file-drop__drop-zone img {
  height: auto;
  max-height: 250px;
}

h5.separator {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-bottom: 2rem;
  padding-top: 2.2rem;
  font-size: 1.5rem;
  line-height: 1;
  &.top {
    border-top:0;
    margin-top: 0;
    padding-top:0;
  }
}

.ng-bar {
  background-color: variables.$secondary-color !important;
}

.ng-spinner-icon {
  border-top-color: variables.$secondary-color !important;
  border-left-color: variables.$secondary-color !important;
}

#login-loader .ng-bar-placeholder {
  display: none !important; 
}

#reset-password-loader {
  & .ng-spinner-icon {
    border-top-color: #fff !important;
    border-left-color: #fff !important;
  }
  & .ng-bar {
    background-color: #fff !important;
  }
}

.signup-page .change-subscription .plan-heading {
  display:none;
}

/* Help Scout */
body button.c-BeaconCloseButton {
  display: none !important;
}

html body:has(.main-layout-container.customizer) button.c-BeaconCloseButton,
html body:has(.main-layout-container.page-builder) button.c-BeaconCloseButton {
  display: block !important;
}

body #beacon {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  z-index: 99999;
  border-radius: 50px;
  padding: 0.6rem 1.2rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 8rem;
  & i-feather {
    top: 0 !important;
  }
  &.hide {
    display: none !important;
  }
}

@media screen and (min-width: 959px) {
  #BeaconInlineArticlesFrame {
    width: 420px !important;
  }
}

body .BeaconContainer {
  margin: 5rem 0;
  height: calc(100vh - 10rem) !important;
  right: 1rem !important;
  bottom: 0.5rem !important;
}

body .BeaconNotificationsFrame {
  bottom: 4rem !important;
  right: 0rem !important;
}

body a.help-icon-hs {
  font-size: 1rem;
  font-weight: 700;
  border: 1px solid;
  padding: 0rem 0.3825em;
  border-radius: 50%;
  position: relative;
  top: -0.12rem;
  left: 0.4rem;
  opacity: 1;
  color: #888;
}

body a.help-icon-hs[data-tooltip]:before {
  min-width: 8rem;
}
/* End HelpScout*/

body ngx-skeleton-loader * {
  outline: none;
}

body {
  & .nsm-dialog {
    width: 90%;
    max-width: 670px;
    &.footerWidget {
      max-width:100%;
      height: 100vh;
      & .nsm-content {
        height: 90vh;
      }
    }
    &.nsm-podcast-form,
    &.nsm-plan-form,
    &.participant-modal {
      max-width: 85rem;
    }
    &.customHomepageEditor {
      max-width: 90% !important;
    }
    &.media-library-modal {
      max-width: 82%;
      & .nsm-content {
        padding: 2rem 3rem;
        & .nsm-body {
          overflow: auto;
          max-height: 80vh;
        }
      }
    }
    &.reply-comment-modal {
      max-width: 70%;
      font-size: 1.7rem;
    }
    &.editMediaModal {
      max-width: 70%;
    }
    &.footerBuilder {
      width: 92%;
      max-width: 1920px;
      & .nsm-content {
        width: 100%;
        min-height: 50vh;
        padding: 0;
      }
      & button.nsm-dialog-btn-close {
        top: -1.5rem !important;
        right: unset !important;
        padding: 0;
        background-color: transparent;
        left: 0;
        & svg {
          display: none;
        }
        &:after {
          content: "Done";
          background-color: #0e81d2;
          font-size: 1.45rem;
          cursor: pointer;
          font-weight: 700;
          padding: 6.5px 25px;
          letter-spacing: 0.45px;
          margin: 0;
          display: flex;
          align-content: center;
          align-items: center;
          border-radius: 2px;
          top: 20px;
          left: 6px;
          position: absolute;
        }
      }
    }
    & app-image-upload {
      max-height: 300px;
      display: block;
      & app-media-library {
        display: block;
        width: 100%;
        & .skeleton-loader {
          max-height: 80px;
        }
        & .media-container {
          padding: 3rem 1rem;
          grid-template-columns: repeat(5, minmax(0, 1fr));
        }
        & .media-item {
          min-height: 100px !important;
          & img {
            height: auto !important;
            object-fit: unset;
          }
        }
      }
      & .modal-container {
        position: relative;
        z-index: 999999999;
        width: 98%;
        background: transparent;
        left: 0;
        top: -296px;
        height: auto;
        margin: 0 auto;
        overflow: hidden;
        display: block;
      }
      & .library-modal {
        position: static;
        width: 100%;
        max-height: 270px;
        padding: 0 3%;
        border-radius: 0.375rem;
        overflow: auto;
      }
      & span.dismiss-modal {
        right: 2rem;
        padding: 0;
        z-index: 9999;
      }
    }
  }
  & .new-site-modal .overlay.nsm-overlay-open,
  & .welcome-modal .overlay.nsm-overlay-open {
    background-color: rgba(1, 23, 39, 0.9);
  }
  & .changelog-modal .nsm-content {
    padding-left: 3rem;
    padding-right: 3rem;
    margin-left: 0;
    margin-right: 0;
  }
  & .nsm-content {
    width: 100%;
    min-height: 50vh;
    padding: 5.5rem 7.5rem;
    & input[type="text"],
    & .social-container input,
    & .subscribe-container select {
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
    }
    & .editor-container {
      & input {
        padding-top: .4rem !important;
        padding-bottom: .4rem !important;
      }
      & input#gjs-am-uploadFile {
        padding-top: 150px !important;
        padding-bottom: 150px !important;
      }
      & .gjs-pn-save-btn {
        display: none !important;
      }
      & span.gjs-pn-btn[title="Page Settings"] {
        display: none;
      }
      & .gjs-pn-commands {
        visibility: hidden !important;
      }
      & .gjs-editor-cont, #sidebar-left {
        top: 9px;
      }
    }
    & .flex {
      & button, input[type=submit], button.submit, a.button {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  & .customize-section .nsm-content div.editor-container input[type=range] {
    top: -1px !important;
  }
  & button.nsm-dialog-btn-close svg {
    color: #fff;
    width: 0.8rem;
    height:.8rem;
  }
  & button.nsm-dialog-btn-close {
    display:block;
    background-color: variables.$secondary-color;
    margin: 0;
    border-radius: 50%;
    padding: 1.2rem;
    top: -1.5rem;
    right: -1.5rem;
    &:hover {
      background-color: variables.$secondary-color-light;
    }
  }
  & .new-site-modal {
    & .nsm-dialog {
      width: 100vw !important;
      margin: 0 !important;
      max-width: 100% !important;
    }
    & .nsm-body {
      flex: unset;
      width: 100%;
      max-width: 100vw;
      height: 100vh;
    }
    & .nsm-content {
      margin: 0 !important;
      min-height: 100vh;
      max-width: 100%;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
    & .nsm-dialog-btn-close {
      display: none !important;
    }
  }
  .memberships-modal {
    & .nsm-dialog {
      max-width: 700px;
    }
  }
}

.default-notice {
  margin-top: 3rem;
  text-align: center;
  padding: 1.4rem 2rem;
  background-color: color.adjust(variables.$secondary-color, $lightness: 54%);
  border: 1px solid variables.$secondary-color;
  border-radius: .4rem;
  line-height: 1.4;
  font-size: 1.3rem;
  & i {
    color: variables.$secondary-color;
  }
}

body .https-input {
  -moz-user-select: none; -webkit-user-select: none; -ms-user-select:none; user-select:none;-o-user-select:none;
}

.repeatable-inputs {
  & .drag-icon {
    font-size: 2rem;
    opacity: 0.25;
    margin-right: 1rem;
    cursor: move;
    position: relative;
    top: 1.2rem;
    transition: all 250ms ease-in-out;
    &:hover {
      opacity: .6;
    }
  }
  
  & .trash-icon {
    font-size: 2rem;
    opacity: 0.15;
    color: variables.$error-color;
    margin-left: 2rem;
    cursor: pointer;
    position: relative;
    top: 1.2rem;
    transition: all 250ms ease-in-out;
    &:hover {
      opacity: .5;
    }
  }
}

.customizer-toggle {
  margin-top: 1rem;
}

.customizer-themes {
  
  & app-select-theme p {
    visibility: hidden;
    margin-bottom: 0;
  }

  & .theme-submit {
    margin-bottom: 0;
    margin-top: 2rem;
  }

}

// DATEPICKER
dp-date-picker {
  margin-bottom: 0.75rem;
  display: none;
  width: 100%;
  appearance: none;
  border-radius: 0.375rem;
  border: 1px solid #bfbfbf;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  line-height: 1.25;
}

.dp-day-calendar-container button,
.dp-month-calendar-container button,
.dp-day-calendar-container button.dp-nav-header-btn:hover,
.dp-month-calendar-container button:hover,
.dp-nav-btns-container button:hover,
.dp-time-select-controls button,
.dp-time-select-controls button:hover {
  padding: initial;
  background: initial;
  background-color: initial;
  color: initial;
  font-size: 1.1rem;
}
.dp-nav-btns-container button:hover {
  color: initial !important;
  background-color: initial !important;
}
.dp-day-calendar-container button:hover,
.dp-calendar-week .dp-calendar-day.dp-selected {
  color: #fff;
  background-color: variables.$secondary-color;
}
dp-calendar-nav .dp-current-location-btn, 
dp-calendar-nav .dp-calendar-nav-left:before,
dp-calendar-nav .dp-calendar-nav-right:before,
dp-time-select .dp-time-select-control-down:before,
dp-time-select .dp-time-select-control-up:before {
  border-color: variables.$primary-color;
}
button.dp-current-month.dp-current-day {
  border: 1px solid;
}
span.dp-calendar-weekday {
  font-size: 1.1rem;
  line-height: 1.8;
}
// END DATEPICKER

.customizer-toggle .toggle__dot {
  border: 1px solid #b9b9b9;
}

.customizer-toggle .active .toggle__dot {
  background-color: variables.$secondary-color !important;
  border-color: variables.$secondary-color !important;
}
.customizer-toggle app-toggle {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  top: 2px;
}

.customize-iframe-container {
  display:none;
}

.section-customize {
  & button {
    &.back {
      background-color:rgba(0, 0, 0, 0.08);
      color: variables.$primary-color;
      &:hover {
        background-color: rgba(0, 0, 0, 0.18);
      }
    }
    &.live-editing {
      background-color: #f7f7fd;
      color: variables.$secondary-color;
      border: 2px solid variables.$secondary-color;
      &:hover {
        background-color: #fff;
      }
    }
  }
  & .save-customization {
    justify-content: flex-end;
    position: sticky;
    top: 0;
    margin: 0 0 1rem 0;
    padding: 1rem 0;
    z-index: 999;
    width: 50%;
    margin-left: 50%;
    & button {
      box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    }
  }
  .icon-picker .arrow {
    display: none !important;
  }
}

.main-layout-container.customizer .customize-iframe-container {
  display:block;
}

.main-card.section-customize {
  visibility: hidden;
}

.main-layout-container.customizer .main-card.section-customize {
  visibility: visible;
}

.section-customize button.live-editing {
  display:block;
  margin-right: 2rem;
}

.main-layout-container.customizer {
  & .sidebar,
  & .settings-nav {
    display: none;
  }
  &.page-builder {
    .settings-nav {
      display: block
    }
    .dashboard-content, .main-card {
      padding: 0;
      background: transparent;
      box-shadow: none;
    }
    & .compose-container .page-data,
    & .edit-page-heading,
    & .settings-nav.top-section-pages {
      display: none;
    }
    & .page-content-data {
      margin-bottom: 0 !important;
    }
  }
  & .dashboard-content {
    padding:0;
    max-width: 100%;
  }
  & .section-customize app-customize {
    display:flex;
  }
  & .customize-container {
    max-width: calc(21% - 1px);
    width: 21%;
    min-width: 21%;
    background: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    height: 100vh;
    scrollbar-width: thin;
    scrollbar-color: #F5F5F5;
    overflow-y: auto;
    overflow-x: visible;
    padding-top: 0;
    opacity:1;
    &.collapsed {
      min-width:0;
      max-width:0;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 .6rem rgba(0,0,0,0.3);
      border-radius: 1rem;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar {
      width: .6rem;
      background-color: #F5F5F5;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      box-shadow: inset 0 0 .6rem rgba(0,0,0,.3);
      background-color: #b7b7b7;
    }
  }
  & .customize-section {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.2rem;
    padding-top: 0.7rem;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    & h3 {
      font-size: 1.4rem;
      padding-left: 1rem !important;
      margin-left: 0 !important;
      position: relative;
      top:0.33rem;
      &.section-title:after {
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f054";
        opacity: .5;
        top: 1.7px;
        display: inline-block;
        position: absolute;
        right:0;
      }
      &.inner-title {
        text-align: center;
        padding: 1rem 1rem 2rem 1rem;
        margin-bottom: 2.6rem;
        border-bottom: 1px solid rgba(0,0,0,.1);
        cursor: pointer;
        &:before {
          font-family: "Font Awesome 6 Free";
          font-weight: 900;
          content: "\f053";
          opacity: .5;
          top: 11px;
          display: inline-block;
          position: absolute;
          left:1rem;
        }
      }
    }
    &.general {
      border-top: 0;
    }
    &.w-full {
      width: 100%;
    }
    &:before {
      left: 1rem;
    }
    & input.default, 
    textarea.default,
    select.default {
      padding-top: 0.6rem;
      padding-bottom: 0.6rem;
      padding-left: 0.6rem;
      padding-right: 1rem;
      font-size: 1.36rem;
      border-width: 1px;
    }
    & input.colorpicker-input {
      max-width: 80%;
    }
    & .desc {
      margin-top: -0.2rem !important;
      font-style: italic;
    }
    & input[type=range] {
      top: 1.4rem !important;
    }
    & select.default {
      background-position: calc(100% - 16px) calc(1em - 1px), calc(100% - 11px) calc(1em - 1px), calc(100% - 2.2em) 0.3em !important;
    }
    & h5.separator {
      margin-top: 2rem;
    }
    & .mb-10 {
      margin-bottom: 2rem;
    }
  }
  & .customize-sections-wrapper.active .customize-section {
    display: none;
  }
  & .customize-sections-wrapper.active {
    & .customize-section.active {
      display: block;
      & h3 {
        text-align: center;
        font-size: 1.5rem;
        top: 0;
      }
    }
  }
  & .customize-section.active:before {
    transform: rotate(90deg);
  }
  & .delete-image {
    top: -2.4rem !important;
    right: 0 !important;
    & i {
      font-size: 1.4rem !important;
    }
  }
  & .color-picker {
    &.open {
      left: 10px !important;
      box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
    }
    & .arrow {
      display:none !important;
    }
  }
  & .colorpicker-block {
    width: 3.067rem;
    height: 3.067rem;
  }

  & .icon-search input {
    border-bottom: 1px solid #cacaca;
  }

  & .save-customization {
    margin: 0;
    position: sticky;
    background-color: #fff;
    top: 0;
    text-align: right;
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    width: 100%;
    justify-content: space-between;
    & button {
      font-size: 1.1rem;
      padding: 0.8rem 1.3rem;
      border-radius: .3rem;
      &.live-editing {
        display:none;
      }
      &.back {
        margin-top: -1.1rem;
        margin-bottom: -1.1rem;
        margin-left: -1rem;
        border-radius: 0;
        min-width: 50px;
        box-shadow:none;
        cursor: pointer;
        &:hover {
          transform:none;
        }
      }
    }
  }
  & .subscribe-container,
  & .social-container {
    padding-right: 0;
  }
  & .footer-widgets {
    display:block !important;
  }
  & .repeatable-inputs  {
    display: block !important;
    & .trash-icon {
      margin-left: 0;
      position: absolute;
      top: -1rem;
      left: 5rem;
    }
    & .drag-icon {
      top: -1rem;
    }
    &.subscribe,
    &.social,
    &.footer-widget,
    &.navigation-item {
      margin-bottom: 3rem;
      position: relative;
    }
    &.social input.default:not([type="text"]) {
      padding-left: 6rem;
      text-transform: capitalize;
    }
    &.social i.selected-input-icon {
      top: 0.75rem;
      left: 1.2rem;
      font-size: 1.6rem;
    }
  }
  & app-customize-sidebar-widget .repeatable-inputs {
    margin: 3rem 0 5rem 0;
  }
  & .navigation-items-label,
  & .footer-widgets-label {
    margin-bottom: 4rem;
  }
  & .add-widget {
    margin: 0;
  }
  & .font-picker {
    top: 240px !important;
    width: auto !important;
  }
  & .upload-file-container {
    padding: 1.2rem;
  }
  & .menu-name-input {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
  }
  & .upload-file-container {
    transform: scale(0.9);
    & > i-feather {
      width: 50px !important;
      height: 50px !important;
    }
    & button:hover {
      transform: none;
    }
  }
}

// Temporary workaround/fix for drag & drop editor in modal (on customize area, Mac + Chrome)
body.dialog-open .main-layout-container.customizer .section-customize app-customize {
  display: block;
  & .customize-container {
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
}
body.dialog-open .main-layout-container.customizer:not(.page-builder) .w-full {
  visibility: hidden;
}
body.dialog-open .main-layout-container.customizer .nsm-body .w-full {
  visibility: visible;
}
body.dialog-open .main-layout-container.customizer .overlay.nsm-overlay-open {
  background-color: #000000df;
}
// end temp fix

body.dialog-open button#beacon {
  display: none;
}

// Full-width editor
.main-layout-container.customizer.page-builder .top-section-blog,
.main-layout-container.customizer.page-builder .top-section-podcasts,
.main-layout-container.customizer.page-builder .top-section-videos,
.main-layout-container.customizer.page-builder h3:has(.fa-chevron-left) {
  display: none;
}
.main-editor-wrapper .featured-media iframe {
  justify-self: center;
  &[src*='youtube'],
  &[src*='youtu.be'] {
    width: 80%;
    margin: 0 auto;
    height: auto;
    aspect-ratio: 16 / 9;
  }
}

body.dialog-open .main-editor-wrapper app-content-editor-wrapper {
  opacity: 0;
}

// end blog editor

@media screen and (max-width: 1100px) {
  body .main-layout-container.customizer.page-builder .dashboard-content {
    padding: 0 !important;
  }
  body .gjs-pn-options {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  // Mobile customizer styles
  body .customize-container {
    max-width: calc(100% - 1px) !important;
    width: 100% !important;
    min-width: 99% !important;
  }
  body .main-layout-container.customizer .dashboard-content {
    padding: 0 !important;
  }
  body .main-layout-container.customizer.page-builder .dashboard-content,
  body .main-layout-container.customizer.page-builder .main-card {
    padding: 0 !important;
  }
  body app-live-preview, body .customize-width-toggle {
    display: none !important;
  }
  body .main-layout-container.customizer .customize-section {
    padding-bottom: 2rem;
    padding-top: 1.5rem;
  }
  body .main-layout-container.customizer .customize-section h3 {
    font-size: 1.8rem;
  }
  body .gjs-pn-options {
    display: block;
  }
  body table.content-table td a {
    padding: 0;
  }

  body table.content-table td {
    padding: 2rem 1.4rem !important;
  }

}

body ul.ngx-pagination {
  text-align: right;
  margin-bottom: 0;
  display: flex;
  align-items: baseline;
  & li, 
  & li.current {
    font-size: 1.3rem;
    margin-right: 0;
    transition: background-color 250ms ease-in-out;
    background: transparent;
    border-radius: 0.375rem;
  }

  & li a {
    padding: .8rem 1.6rem;
    color: variables.$primary-color;
    margin: 0;
    border-radius: 0.375rem;
  }

  & li:hover a, 
  & li.current {
    color: #fff;
    background: variables.$secondary-color;
  }

  & li.disabled, 
  & li.current {
    padding: .8rem 1.6rem !important;
    margin: 0 0.3rem;
  }

  & li.pagination-previous,
  & li.pagination-next {
    position: relative;
    top: 1px;
    &:hover a {
      color: variables.$primary-color;
      background-color: color-mix(in srgb, variables.$secondary-color 20%, #fff) !important;
    }
  }

  & .pagination-previous a:before,
  & .pagination-next a:after {
    display:block;
    margin: 0;
    padding:0;
  }

  & .pagination-previous a:before,
  & .pagination-previous.disabled:before {
    content: "\f053";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    margin: 0;
  }

  & .pagination-next a:after,
  & .pagination-next.disabled:after {
    content: "\f054";
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    margin:0;
  }

  .small-screen {
    padding: .8rem 1.6rem;
    border: 1px solid #e2e8f0;
  }

}

// CUSTOM ICON DISPLAY
body .icon-picker {
  & .fa-500px,
  .fa-airbnb,
  .fa-alipay,
  .fa-amazon,
  .fa-android,
  .fa-angellist,
  .fa-apple,
  .fa-apple-pay,
  .fa-app-store,
  .fa-artstation,
  .fa-audible,
  .fa-bandcamp,
  .fa-behance,
  .fa-bitcoin,
  .fa-blogger,
  .fa-blogger-b,
  .fa-bluesky,
  .fa-btc,
  .fa-chrome,
  .fa-dailymotion,
  .fa-deezer,
  .fa-delicious,
  .fa-dev,
  .fa-deviantart,
  .fa-diaspora,
  .fa-digg,
  .fa-discord,
  .fa-discourse,
  .fa-docker,
  .fa-dribbble,
  .fa-ebay,
  .fa-ethereum,
  .fa-etsy,
  .fa-facebook,
  .fa-facebook-f,
  .fa-facebook-messenger,
  .fa-figma,
  .fa-firefox,
  .fa-flickr,
  .fa-flipboard,
  .fa-foursquare,
  .fa-get-pocket,
  .fa-github,
  .fa-github-alt,
  .fa-gitlab,
  .fa-goodreads,
  .fa-goodreads-g,
  .fa-google,
  .fa-google-play,
  .fa-hacker-news,
  .fa-imdb,
  .fa-instagram,
  .fa-itunes,
  .fa-itunes-note,
  .fa-kickstarter,
  .fa-kickstarter-k,
  .fa-lastfm,
  .fa-line,
  .fa-linkedin,
  .fa-linkedin-in,
  .fa-mailchimp,
  .fa-mastodon,
  .fa-medium,
  .fa-meetup,
  .fa-meta,
  .fa-microsoft,
  .fa-mixcloud,
  .fa-patreon,
  .fa-paypal,
  .fa-pinterest,
  .fa-pinterest-p,
  .fa-product-hunt,
  .fa-quora,
  .fa-reddit,
  .fa-reddit-alien,
  .fa-salesforce,
  .fa-shopify,
  .fa-skype,
  .fa-slack,
  .fa-snapchat,
  .fa-soundcloud,
  .fa-spotify,
  .fa-square-behance,
  .fa-square-dribbble,
  .fa-square-facebook,
  .fa-square-github,
  .fa-square-gitlab,
  .fa-square-instagram,
  .fa-square-lastfm,
  .fa-square-pinterest,
  .fa-square-reddit,
  .fa-square-snapchat,
  .fa-square-steam,
  .fa-square-threads,
  .fa-square-tumblr,
  .fa-square-twitter,
  .fa-square-vimeo,
  .fa-square-whatsapp,
  .fa-square-xing,
  .fa-square-x-twitter,
  .fa-square-youtube,
  .fa-stack-exchange,
  .fa-stack-overflow,
  .fa-steam,
  .fa-steam-symbol,
  .fa-strava,
  .fa-stripe,
  .fa-stripe-s,
  .fa-stumbleupon,
  .fa-stumbleupon-circle,
  .fa-telegram,
  .fa-threads,
  .fa-tiktok,
  .fa-tumblr,
  .fa-twitch,
  .fa-twitter,
  .fa-unsplash,
  .fa-untappd,
  .fa-viber,
  .fa-vimeo,
  .fa-vimeo-v,
  .fa-vine,
  .fa-vk,
  .fa-weixin,
  .fa-whatsapp,
  .fa-wikipedia-w,
  .fa-windows,
  .fa-wordpress,
  .fa-wordpress-simple,
  .fa-xing,
  .fa-x-twitter,
  .fa-yahoo,
  .fa-yammer,
  .fa-yandex,
  .fa-yandex-international,
  .fa-y-combinator,
  .fa-yelp,
  .fa-youtube,
  .fa-gg.fab, .fa-adn, .fa-accusoft, .fa-accessible-icon, .fa-gg-circle {
    display: inline-block !important;
  }
}