// Tablet
@media screen and (max-width: 1100px) {
  // REM Set
  html {
    font-size: 7px !important;
  }
  // Login and Signup
  .login-container {
    min-width: 60%;
  }
  body .sidebar {
    min-width: 150px !important;
    max-width: 150px !important;
  }
  // Dashboard
  body .dashboard-content {
    padding: 2rem 1rem !important;
    max-width: calc(100% - 150px);
  }

  body .signup-plans, body .signup-form {
    height: auto !important;
    min-height: 100vh;
  }

  body .signup-page .login-link {
    display:none;
  }

  .support-link-container {
    display: none;
  }
  .section-customize button.live-editing {
    display: none;
  }

  .sidebar-menu-list {
    height: 100% !important;
  }

  body .color-picker.open {
    right: 20px !important;
    left: auto !important;
  }

}

// Mobile 
@media screen and (max-width: 767px) {
  
  // General
  body .text-5xl {
    font-size: 2.5rem;
  }

  // Login and Signup
  .login-page, .reset-page {
    padding: 0 1rem;
    height: auto !important;
    background-image: none !important;
  }
  .login-container, .reset-container {
    width: 88%;
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  body .signup-page {
    width: 100%;
    display:block !important;
    & .logo {
      width: 42px;
    }
  }

  body label.default {
    font-size: 1.8rem !important;
  }

  body input.default, body textarea.default, body select.default {
    font-size: 2rem;
  }

  body button, input[type=submit], body button.submit, a.button {
    font-size: 1.8rem !important;
  }

  body .signup-plans, body .signup-form {
    width: 100% !important;
    padding-left: 4% !important;
    padding-right: 4% !important;
    height: auto !important;
    min-height: auto !important;
    display: block !important;
  }

  .signup-form form div {
    display: block !important;
    padding-left: 0 !important;
    margin-bottom: 1.5rem !important;
  }
  .login-link {
    display: none !important;
  }
  .login-page img.logo {
    width: 40px;
    margin: 0 auto 2rem auto;
  }

  // Main Layout 
  body .main-layout-container {
    display: block !important;
  }
  body .sidebar {
    width: 100% !important;
    max-width: 100% !important;
    height: auto !important;
    position: static !important;
    overflow:hidden !important;
    & .logo {
      max-width: 32rem;
    }
  }
  .mobile-menu {
    display: block !important;
    position: absolute;
    top: 2.3rem;
    right: 4rem;
    font-size: 3.4rem;
    cursor: pointer;
  }

  .sidebar-menu {
    max-height: 7.8rem;
    transition: all 250ms ease;
    &.active {
      max-height: 999rem;
    }
  }

  body .dashboard-content {
    max-width: 100%;
  }

  body .settings-nav-list {
    display: block !important;
    & li {
      margin-bottom: 2rem !important;
    }
  }

  body .media-item {
      margin-bottom: 3rem;
  }

  app-sites, .billing-container, body .grid {
    display: block !important;
  }

  body .subscription, .next-charge {
    width: 100% !important;
    margin-bottom: 5rem;
    & button {
      margin-bottom: 2.5rem;
    }
  }

  body .main-card, body .customize-section {
    padding-left: 1.6rem !important;
    padding-right: 1.6rem !important;
  }
  
  .customize-section:before {
    left: initial !important;
    right: 4rem !important;
  }

  .integrations-container > div {
    margin-bottom: 10rem;
  }

  body .main-card.section-customize,
  body .main-card.section-general {
    padding: 0 !important;
  }

  body .signup-form .signup-header {
    text-align: center;
    & h1 {
      margin: 1rem 0;
    }
    & .trial {
      font-size: 1.5rem;
    }
  }

  body .card.add-site {
    margin-top: 5rem;
  }

  body .nsm-content .flex {
    display: block;
  }

  body .nsm-content input {
      width: 100% !important;
      margin-bottom: 3rem;
      font-size: 1.8rem;
  }

  body .nsm-content .nsm-body button {
      width: 100%;
      display: block;
      min-width: auto !important;
  }

  body .nsm-content {
      min-height: 30vh !important;
  }

  body .sidebar-menu-list li a {
    font-size: 1.8rem;
  }

  body .error-label {
    font-size: 1.525rem !important;
    margin-top: 1.8rem !important;
  }

  body .text-2xl {
    font-size: 1.8rem;
  }

  body .card {
    min-height: 150px !important;
  }

  body .dashboard-content .desc {
    font-size: 1.525rem;
    margin-top: 0;
  }

  body input, body input.default {
    font-size: 2.2rem;
  }

  body .https-input {
    width: 10rem !important;
  }

  body .podcastpage-input {
    width: 18.5rem !important;
  }

  body .settings-nav-list a {
    font-size: 2.2rem; 
  }

  body .settings-nav h3 {
    font-size: 2.6rem;
  }

  body button.add-feed-button {
    margin-left: 0;
  }

  body .nsm-body .text-xl {
    font-size: 1.7rem;
  }

  body .new-site-modal {
    & .nsm-dialog {
      width: 94% !important;
    }
    & .create-website-desc {
      padding: 0;
    }
    & label.default {
      position: static;
      margin-bottom: 1rem !important;
    }
  }

  app-onboarding-progress {
    display: none;
  }

  body .nsm-content .flex {
    & a.button,
    & button,
    & button.submit,
    & input[type=submit] {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }

  body .advanced-input-container {
    padding-left: 1.6rem;
  }

  body .https-input {
    display: none;
  }

  body .sidebar-trial-notice {
    display:none;
  }

  body .sidenav {
      position: static;
      opacity: 1 !important;
      visibility: visible !important;
  }

  body .sub-link-container li a .submenu-icon {
      display: none;
  }

  body .support-section.sub-section {
      display: none;
      &.active {
        display:block;
      }
  }

  body .support-section > ul > li > a {
      display: none !important;
  }

  body .support-section {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }

  body {
    & .episodes-table,
    & .posts-table,
    & .pages-table {
      & th {
        display: none;
      }
      & tr, & td {
        font-size: 1.5rem;
      }
      & tr {
        display: block !important;
        &:hover {
          background: #fff !important;
        }
      }
      & td {
        display: inline-block !important;
        padding: 2rem 3rem !important;
      }
      & td.table-title {
        width: 100%;
        display: block !important;
        font-size: 1.8rem;
      }
      & td.table-data-col {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
        display: inline-block !important;
      }
      & td.table-data-col {
        width: 25%;
        min-width: 25%;
        max-width: 25%;
        display: inline-block !important;
        border-top: 0;
        text-align: left;
      }
      & td.table-data-col.data-date {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border-right: 0;
      }
      & tr td:nth-child(4) {
        border-left: 0;
        border-right: 0;
      }
      & .table-data-col.duplicate-content {
        display: none !important;
      }
      & tbody tr:nth-child(2n) {
        background: #f5f5f5;
        &:hover {
          background: #f5f5f5 !important;
        }
      }
      & .skeleton td:first-child {
        width: 100%;
      }
    }
    table.content-table td {
      border: 0 !important;
    }
    & .table-actions {
      font-size: 1.5rem !important;
    }
    & .table-controls {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem !important;
      & > div {
        width: 100%;
      }
      & select {
        min-width: 25rem !important;
      }
    }
  }

  body .domains-container {
    & .input-group {
      margin: 5rem 0;
    }

    & .input-form {
      display: block;
    }

    & .input-form button.save-form {
      margin: 0.3rem 0 1.6rem 5rem;
      text-align: right;
      float: right;
    }

    & .advanced-input-container input {
      font-size: 1.6rem;
    }

    & .advanced-input-container input.disabled {
      opacity: 0.6;
      text-align: right;
      padding-right: 3rem;
    }

    & i-feather.subdomain-ssl-enabled {
      width: 4.2rem !important;
      height: 4.2rem !important;
    }

    & .custom-ssl-status i-feather {
      width: 2.1rem !important;
      height: 2.1rem !important;
    }
  }

  body .nsm-content {
    padding: 2.5rem 3.5rem !important;
  }

  body .sites-section.sub-section {
    & .sidenav {
      display: none !important;
    }
    & a {
      font-size: 1.8rem;
    }
  }

  body [data-tooltip]:before {
    display: none !important;
  }

}