@use 'src/styles/variables' as variables;

body .checkbox-container input.default[type=checkbox],body input.default[type=checkbox] {
  width: auto;
  min-width: 1rem;
  appearance: auto!important;
  -webkit-appearance: checkbox!important;
  -moz-appearance: auto!important;
  display: inline-block;
  margin: 0 1rem 0 0;
  background: none;
  padding: 0;
  border: none
}

body input.default,body select.default,body textarea.default {
  margin-bottom: .75rem;
  display: block;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(229,231,235,var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255,255,255,var(--tw-bg-opacity));
  padding: 1rem 1.5rem;
  line-height: 1.25;
  border-color: variables.$default-border-color;
}

body input.default:focus,body select.default:focus,body textarea.default:focus {
  border-color: variables.$secondary-color;
}

body input.default[disabled=disabled],body input.default[disabled=true],body select.default[disabled=disabled],body select.default[disabled=true],body textarea.default[disabled=disabled],body textarea.default[disabled=true] {
  background-color: rgba(0,0,0,.03);
  border-color: rgba(0,0,0,.05);
  color: rgba(0,0,0,.7)
}

body input.default.slider {
  width: 8rem;
  margin-right: 2%
}

body label.default {
  margin-bottom: .5rem;
  display: block;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: .025em;
  --tw-text-opacity: 1;
  color: rgba(55,65,81,var(--tw-text-opacity))
}

body label.default.auth {
  color: variables.$primary-color;
  font-size: 1.5rem;
  line-height: 2rem
}

body label.default.required:after {
  content: "*";
  display: inline;
  color: variables.$error-color;
  margin-left: .4rem
}

body .desc {
  margin-top: -.5rem;
  margin-left: .2rem;
  color: #4a5565
}

body .desc,body .error-label {
  font-size: 1.125rem;
  line-height: 1.75rem
}

body .error-label {
  margin-top: .5rem;
  color: variables.$error-color;
}

body input[type=range] {
  position: relative;
  top: 1.9rem
}

body input[type=range].slider-thumb::-webkit-slider-thumb {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 9999px;
  background-color: variables.$secondary-color;
}

body input[type=range].slider-thumb::-webkit-slider-thumb:hover {
  background-color: variables.$secondary-color;
}

body select.default {
  background-image: linear-gradient(45deg,transparent 50%,gray 0),linear-gradient(135deg,gray 50%,transparent 0),linear-gradient(90deg,#ccc,#ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),calc(100% - 15px) calc(1em + 2px),calc(100% - 2.5em) .5em;
  background-size: 4px 5px,5px 5px,1px 1.5em;
  background-repeat: no-repeat
}

@media (min-width: 768px) {
  .md\:mb-0 {
      margin-bottom:0
  }

  .md\:w-1\/2 {
      width: 50%
  }

  .md\:text-base {
      font-size: 1rem;
      line-height: 1.5rem
  }
}

// MULTISELECT - todo: cleanup
body .ng-clear-wrapper {
  display: none !important;
}
.ng-select.ng-select-opened>.ng-select-container{
  background:#fff;
  border-color:#b3b3b3 #ccc #d9d9d9
}
.ng-select.ng-select-opened>.ng-select-container:hover{
  box-shadow:none
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow{
  top:-2px;
  border-color:transparent transparent #999;
  border-width:0 5px 5px
}
.ng-select.ng-select-opened>.ng-select-container .ng-arrow:hover{
  border-color:transparent transparent #333
}
.ng-select.ng-select-opened.ng-select-top>.ng-select-container{
  border-top-right-radius:0;
  border-top-left-radius:0
}
.ng-select.ng-select-opened.ng-select-right>.ng-select-container{
  border-top-right-radius:0;
  border-bottom-right-radius:0
}
.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container{
  border-bottom-right-radius:0;
  border-bottom-left-radius:0
}
.ng-select.ng-select-opened.ng-select-left>.ng-select-container{
  border-top-left-radius:0;
  border-bottom-left-radius:0
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container{
  border-color:#007eff;
}
.ng-select.ng-select-disabled>.ng-select-container{
  background-color:#f9f9f9
}
.ng-select .ng-has-value .ng-placeholder{
  display:none
}
.ng-select .ng-select-container{
  color:#333;
  background-color:#fff;
  border-radius:4px;
  border:1px solid #ccc;
  min-height:40px;
  align-items:center
}
.ng-select .ng-select-container:hover{
  box-shadow:0 1px 0 rgba(0,0,0,0.06)
}
.ng-select .ng-select-container .ng-value-container{
  align-items:center;
  padding-left:10px
}
.ng-select .ng-select-container .ng-value-container .ng-placeholder{
  color:#999
}
.ng-select.ng-select-single .ng-select-container{
  height:36px
}
.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input{
  top:5px;
  left:0;
  padding-left:10px;
  padding-right:50px
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value{
  background-color:#f9f9f9;
  border:1px solid #e6e6e6
}
.ng-select.ng-select-multiple.ng-select-disabled>.ng-select-container .ng-value-container .ng-value .ng-value-label{
  padding:0 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container{
  padding-top:5px;
  padding-left:7px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value{
  font-size:.9em;
  margin-bottom:5px;
  color:#fff;
  background-color: variables.$secondary-color;
  border-radius:2px;
  margin-right:5px;
  display:flex;
  align-items: center;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled{
  background-color:#f9f9f9
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value.ng-value-disabled .ng-value-label{
  padding-left:5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label{
  display:inline-block;
  padding:1px 5px;
  white-space: pre-wrap;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{
  display:inline-block;
  padding:1px 5px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon:hover{
  opacity: .8;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left{
  border-right:1px solid #b8dbff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right{
  border-left:1px solid #b8dbff
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input{
  padding:0 0 3px 3px
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-input>input{
  color:#000
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder{
  top:8px;
  padding-bottom:5px;
  padding-left:3px
}
.ng-select .ng-clear-wrapper{
  color:#999
}
.ng-select .ng-clear-wrapper:hover .ng-clear{
  color:#D0021B
}
.ng-select .ng-clear-wrapper:focus .ng-clear{
  color:#D0021B
}
.ng-select .ng-clear-wrapper:focus{
  outline:none
}
.ng-select .ng-spinner-zone{
  padding:5px 5px 0 0
}
.ng-select .ng-arrow-wrapper{
  width:25px;
  padding-right:5px
}
.ng-select .ng-arrow-wrapper:hover .ng-arrow{
  border-top-color:#666
}
.ng-select .ng-arrow-wrapper .ng-arrow{
  border-color:#999 transparent transparent;
  border-style:solid;
  border-width:5px 5px 2.5px
}
.ng-dropdown-panel{
  background-color:#fff;
  border:1px solid #ccc;
  box-shadow:0 1px 0 rgba(0,0,0,0.06);
  left:0
}
.ng-dropdown-panel.ng-select-top{
  bottom:100%;
  border-top-right-radius:4px;
  border-top-left-radius:4px;
  border-bottom-color:#e6e6e6;
  margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-top .ng-dropdown-panel-items .ng-option:first-child{
  border-top-right-radius:4px;
  border-top-left-radius:4px
}
.ng-dropdown-panel.ng-select-right{
  left:100%;
  top:0;
  border-top-right-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  border-bottom-color:#e6e6e6;
  margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-right .ng-dropdown-panel-items .ng-option:first-child{
  border-top-right-radius:4px
}
.ng-dropdown-panel.ng-select-bottom{
  top:100%;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  border-top-color:#e6e6e6;
  margin-top:-1px
}
.ng-dropdown-panel.ng-select-bottom .ng-dropdown-panel-items .ng-option:last-child{
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px
}
.ng-dropdown-panel.ng-select-left{
  left:-100%;
  top:0;
  border-top-left-radius:4px;
  border-bottom-right-radius:4px;
  border-bottom-left-radius:4px;
  border-bottom-color:#e6e6e6;
  margin-bottom:-1px
}
.ng-dropdown-panel.ng-select-left .ng-dropdown-panel-items .ng-option:first-child{
  border-top-left-radius:4px
}
.ng-dropdown-panel .ng-dropdown-header{
  border-bottom:1px solid #ccc;
  padding:5px 7px
}
.ng-dropdown-panel .ng-dropdown-footer{
  border-top:1px solid #ccc;
  padding:5px 7px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup{
  user-select:none;
  padding:8px 10px;
  font-weight:500;
  color:rgba(0,0,0,0.54);
  cursor:pointer
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-disabled{
  cursor:default
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-marked{
  background-color:#f5faff
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup.ng-option-selected.ng-option-marked{
  color:#fff;
  background-color: variables.$secondary-color;
  font-weight:600
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  background-color:#fff;
  color:rgba(0,0,0,0.87);
  padding:8px 10px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
  color:#fff;
  background-color: variables.$secondary-color
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label,.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label{
  font-weight:600
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{
  background-color:#f5faff;
  color:#333
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-disabled{
  color:#ccc
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child{
  padding-left:22px
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .ng-tag-label{
  font-size:80%;
  font-weight:400;
  padding-right:5px
}
